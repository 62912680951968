<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="8">
            <h4 id="traffic" class="card-title mb-0">List All BAST</h4>
            <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
          </CCol>
          <CCol sm="4" class="col align-self-center row justify-content-end">
            <CButton
							class="my-2 text-center"
							color="danger"
							variant="outline"
							square
							size="sm"
							@click="generatePdf"
						>
							Generate PDF
						</CButton> &nbsp;
				    <download-excel
              class="btn btn-default"
              :data="filteredItems"
              :fields="jsonFields"
              worksheet="Sheet1"
              name="list-all-bast.xls"
            >
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
              >
                Download Excel
              </CButton>
            </download-excel>
			    </CCol>
        </CRow>

        <CRow class="mt-5">
					<CCol sm="3">
						<CInput
							type="date"
							label="Date From"
							placeholder=""
							description="Masukkan tanggal awal pencarian."
							v-model="start_date"
							required
						/>
					</CCol>
          <CCol sm="3">
						<CInput
							type="date"
							label="Date To"
							placeholder=""
							description="Masukkan tanggal akhir pencarian."
							v-model="end_date"
							required
						/>
					</CCol>
          <CCol sm="3">
            <CSelect 
							:value.sync="company_name"
							name="company_name"
							label="Company Name"
							:options="companies"
						/>
          </CCol>
          <CCol sm="3">
            <CButton
              style="margin-top: 30px;"
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="filterRange"
            >
              Apply
            </CButton> &nbsp;
            <CButton
              style="margin-top: 30px;"
              color="success"
              variant="outline"
              square
              size="sm"
              @click="filterRefresh"
            >
              Refresh List
            </CButton>
          </CCol>
        </CRow>

        <CDataTable
					hover
					striped
					border
					small
					sorter
					:items="filteredItems"
					:fields="fields"
          :items-per-page="perPage"
				  pagination
					:loading="isLoading"
				>
					<template #id="{item}">
						<td align="center">{{filteredItems.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
					</template>
          <template #created_at="{item}">
            <td align="right">{{item.created_at === null ? '' : date(item.created_at, 'DD MMM YYYY HH:mm')}}</td>
          </template>
          <template #visit_id="{item}">
						<td align="center">
							<a style="cursor: pointer; color: blue;" @click="detail(item.visit_id)">{{item.visit_id}}</a>
						</td>
					</template>
          <template #status="{item}">
            <td align="">{{item.status === 1 ? 'Barang Masuk' : 'Barang Keluar'}}</td>
          </template>
          <template #isDualFeed="{item}">
            <td align="">{{item.isDualFeed === 1 ? 'Ya' : 'Tidak'}}</td>
          </template>
          <template #action="{item}">
						<td align="center">
							<a style="cursor: pointer; color: green;" @click="preview(item.id)">
                <CIcon size="lg" name="cil-cloud-download" />
              </a>
						</td>
					</template>
				</CDataTable>

        <vue-html2pdf
					:show-layout="true"
					:float-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					filename="Lintasarta-List-Bast"
					:pdf-quality="2"
					:manual-pagination="true"
					pdf-format="a4"
					pdf-orientation="portrait"
					pdf-content-width="100%"
					@hasStartedGeneration="hasStartedGeneration()"
					@hasGenerated="hasGenerated($event)"
					:html-to-pdf-options="htmlToPdfOptions"
					ref="html2Pdf"
					class="d-none"
				>
          <section slot="pdf-content" id="content">
            <ThePdfHeader title="Report List BAST" :reportFrom="date(start_date, 'DD MMMM YYYY')" :reportTo="date(end_date, 'DD MMMM YYYY')" :reportFor="'Company Name: ' + company_name" />

            <CDataTable
					    border
					    small
					    :items="filteredItems"
					    :fields="pdfFields"
              :items-per-page="perPage"
				    >
					    <template #id="{item}">
						    <td align="center">{{filteredItems.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
					    </template>
              <template #created_at="{item}">
                <td align="right">{{item.created_at === null ? '' : date(item.created_at, 'DD MMM YYYY HH:mm')}}</td>
              </template>
              <template #status="{item}">
                <td align="">{{item.status === 1 ? 'Barang Masuk' : 'Barang Keluar'}}</td>
              </template>
              <template #isDualFeed="{item}">
                <td align="">{{item.isDualFeed === 1 ? 'Ya' : 'Tidak'}}</td>
              </template>
				    </CDataTable>
          </section>
        </vue-html2pdf>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import http from '@/utils/http-common';
import moment from 'moment';

import VueHtml2pdf from 'vue-html2pdf';
import ThePdfHeader from '@/containers/ThePdfHeader.vue';

export default {
  components: {
    VueHtml2pdf, ThePdfHeader
  },
  data () {
		return {
      company_id: +localStorage.getItem('company_id'),
      role: +localStorage.getItem('role'),
      companies: [],
      company_name: 'All',
      items: [],
      filteredItems: [],
			fields: [
        { key: 'id', label: 'No' },
        'created_at',
        'visit_id',
        'company_name',
        'email',
        'status',
        'first_party',
        'second_party', 
        'officer_approval',
        'security_approval',
        'approval_note',
        'item_name',
        'serial',
        'rack',
        'total',
        { key: 'isDualFeed', label: 'Dual Feed' },
        { key: 'action', label: ' ' },
      ],
      pdfFields: [
        { key: 'id', label: 'No' },
        'created_at',
        'visit_id',
        'company_name',
        'email',
        'status',
        'first_party',
        'second_party', 
        'officer_approval',
        'security_approval',
        'approval_note',
        'item_name',
        'serial',
        'rack',
        'total',
        'isDualFeed',
      ],
      jsonFields: {
        No: {
          field: "id",
          callback: (value) => {
            return this.filteredItems.map(function(x) {return x.id; }).indexOf(value)+1;
          },
        },
        "Created At": {
          field: "created_at",
          callback: (value) => {
            return this.date(value, 'DD MMMM YYYY');
          },
        },
        "Visit ID": 'visit_id',
        "Company Name": 'company_name',
        Status: {
          field: "status",
          callback: (value) => {
            return value === 1 ? 'Barang Masuk' : 'Barang Keluar';
          },
        },
        "First Party": 'first_party',
        "Second Party": 'second_party',
        Officer: 'officer_approval',
        Security: 'security_approval',
        Note: 'approval_note',
        "Item Name": 'item_name',
        Serial: 'serial',
        Rack: 'rack',
        Qty: 'total',
        "Dual Feed": {
          field: "isDualFeed",
          callback: (value) => {
            return value === 1 ? 'Ya' : 'Tidak';
          },
        },
      },
			currentPage: 1,
			perPage: 15,
			totalRows: 1,
			isLoading: true,
      start_date: '',
      end_date: this.date2Str(new Date(), 'yyyy-MM-dd'),
      htmlToPdfOptions: {
				margin: 0,
				filename: 'Lintasarta-List-BAST',
				'pdf-quality': '2',
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				},
				html2canvas: {
					dpi: 300,
					letterRendering: true,
					useCORS: true
				}
			},
    }
  },
  methods: {
    generatePdf () {
			this.$refs.html2Pdf.generatePdf();
		},
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    date2Str(x, y) {
    	const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    	};
    	y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    	});

    	return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length)
    	});
		},
    lastDays() {
      const dt = new Date();
      dt.setDate(dt.getDate() - 30);
      this.start_date = this.date2Str(dt, 'yyyy-MM-dd');
    },
    getData() {
			let self = this;
			return http.get('/report/list-bast')
				.then(function (response) {
          let list = response.data.data;

          if (self.role === 4 || self.role > 5) {
            list = list.filter(function(item) {
    				  return item.company_id === self.company_id;  
					  });
          }
          
					self.items = list;
          self.filteredItems = list;
					self.isLoading = false;
				}).catch(function (error) {
					console.log(error);
				});
		},
    filterRange() {
      let self = this;
      self.filteredItems = [];

      let start = new Date(self.start_date).getTime();
      let end = new Date(self.end_date).getTime();

      let data = self.items.filter(item => {
        let date = new Date(item.created_at).getTime();
        return date >= start && date <= end && item.company_name === self.company_name;
      })

      self.filteredItems = data;
    },
    filterRefresh() {
      this.filteredItems = this.items;
      this.company_name = 'All'
    },
    getCompanies() {
      let self = this;
			return http.get('/companyList')
				.then(function (response) {
          // sort by company_name
          let data = response.data.data.sort((a,b) => (a.company_name > b.company_name) ? 1 : ((b.company_name > a.company_name) ? -1 : 0));
          for (let i = 0; i < data.length; i++) {
					  const items = {};
					  items['label'] = data[i]['company_name'];
					  items['value'] = data[i]['company_name'];
					  self.companies.push(items)
				  }
				}).catch(function (error) {
					console.log(error);
				});
    },
    getName(id) {
      return http.get('/companies/' + id)
				.then(function (response) {
          return response.data.data.company_name;
				}).catch(function (error) {
					console.log(error);
				});
    },
    detail(id) {
      let self = this;
      self.$router.push({ path: '/approval/visit-request/' + id });
    },
    preview(id) {
      let self = this;
      self.$router.push({ path: '/reports/print-bast/' + id });
    }
  },
  mounted: function(){
		this.getData();
    this.lastDays();
    this.getCompanies();
	}
}
</script>

<style scoped>
#content {
  background: #fff;
  font-size: 5px;
  padding: 12px;
}
</style>
